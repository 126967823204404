import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Drawer,
  Divider,
  Paper,
  Avatar,
  Typography,
  IconButton,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import getInitials from "utils/getInitials";
import { Navigation } from "@edaun/components";
import navigationConfig from "./navigationConfig";
import { UserContext } from "auth/UserContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  profile: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing("auto", "auto", "auto", 1.5),
    // marginTop: theme.spacing(0),
  },
  name: {
    marginTop: theme.spacing(0),
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
}));

const NavBar = (props) => {
  const { isDrawerOpen, onDrawerClose, className, ...rest } = props;

  const { isAuthenticated, getName } = useContext(UserContext);

  const classes = useStyles();
  const theme = useTheme();

  // console.log(theme);

  const handleDrawerClose = () => {
    onDrawerClose && onDrawerClose();
  };

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {navigationConfig
          .filter((list) => list.title !== "Support")
          .map((list) => (
            <Navigation
              component="div"
              key={list.title}
              pages={list.pages}
              title={list.title}
            />
          ))}
        {navigationConfig
          .filter((list) => list.title === "Support")
          .map((list) =>
            !isAuthenticated() ? (
              <Navigation
                component="div"
                key={list.title}
                pages={list.pages.filter((v) => v.title !== "Logout")}
                title={list.title}
              />
            ) : (
              <Navigation
                component="div"
                key={list.title}
                pages={list.pages.filter((v) => v.title !== "Login")}
                title={list.title}
              />
            )
          )}
      </nav>
    </div>
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <div className={classes.profile}>
          {isAuthenticated() && (
            <Avatar alt="Person" className={classes.avatar} src={""}>
              {getInitials(getName())}
            </Avatar>
          )}

          <div>
            <Typography className={classes.name} variant="h6">
              {getName()}
              {/* Tico Miladina */}
            </Typography>
            {/* <Typography variant="body2">Manager</Typography> */}
          </div>
        </div>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <Paper
        {...rest}
        className={clsx(classes.root, className)}
        elevation={1}
        square
      >
        {navbarContent}
      </Paper>
    </Drawer>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onDrawerClose: PropTypes.func,
  isDrawerOpen: PropTypes.bool,
};

NavBar.defaultProps = {
  isDrawerOpen: false,
};

export default NavBar;
