export default [
  {
    scope: "edaun_admin:admin",
    pages: [
      "/temp",
      "/hematid/users",
      "/hematid/groups",
      "/hematid/scopes",
      "/hematid/manufacturer",
      "/hematid/user_fee*",
      "/hematid/user_dashboard*",
    ],
  },
  {
    scope: "*",
    pages: [
      "/",
      "/home",
      "/hematid/biz*",
      "/hematid/city_tag*",
      "/hematid/geo_tag*",
      "/hematid/promo*",
      "/hematid/sku*",
      "/hematid/sku2*",
      "/hematid/product*",
      "/hematid/product_brand*",
      "/hematid/product_category*",
      "/hematid/carousel_home*",
      "/hematid/catalog*",
      "/hematid/catalog_source*",
      "/hematid/catalog_category*",
      "/hematid/catalog_kind*",
      "/hematid/manufacturer*",
      "/hematid/product_promo_req*",
    ],
  },
];
