/**
 *
 * @param {String} url - api url query get
 * @param {Object} auth - user authentication
 */
export const get = async (url, auth) => {
  if (auth && auth.accessToken) {
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${auth.accessToken}`,
        },
      });

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (err) {
      throw err;
    }
  } else {
    throw new Error("Unauthorized");
  }
};

/**
 *
 * @param {String} url - api url to update data
 * @param {Object} body - new data
 * @param {Object} auth - user authentication
 */
export const put = async (url, body, auth) => {
  // TODO: isAuth(?)
  try {
    let response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${auth.accessToken}`,
      },
    });
    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      throw data;
    }
  } catch (err) {
    throw err;
  }
};

/**
 *
 * @param {String} url - api url to update data
 * @param {Object} body - new data
 * @param {Object} auth - user authentication
 */
export const patch = async (url, body, auth) => {
  // TODO: isAuth(?)
  try {
    let response = await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${auth.accessToken}`,
      },
    });

    if (response.status === 200) {
      let data = await response.json();
      return data;
    } else {                
      let data2 = await response.json();
      var b = data2.message;    
      throw new Error(b);
    }
  } catch (err) {
    throw err;
  }
};

/**
 *
 * @param {String} url - api url to add data
 * @param {Object} body - new data
 * @param {Object} auth - user authentication
 */
export const post = async (url, body, auth) => {
  // TODO: isAuth(?)
  try {
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${auth.accessToken}`,
      },
    });

    if (response.status === 200) {
      let data = await response.json();
      return data;
    } else {
      throw await response.json();
    }
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

/**
 *
 * @param {String} url - api url query get
 * @param {Object} auth - user authentication
 */
export const del = async (url, auth) => {
  if (auth && auth.accessToken) {
    try {
      let response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Basic ${auth.accessToken}`,
        },
      });

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (err) {
      console.log("err", err);
      throw err;
    }
  } else {
    throw new Error("Unauthorized");
  }
};
