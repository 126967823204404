import { useEffect, useState } from "react";
import { capitalize } from "utils/formatText";
import scopeConfig from "./scopeConfig";
import * as Req from "utils/apiRequest";

const LITEUSER = `${process.env.REACT_APP_AUTH}`;
const API_URL = process.env.REACT_APP_API;

/**
 * manage user state
 */
const useAuth = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [allowedPaths, setAllowedPaths] = useState([]);

  // get token if user already login
  useEffect(() => {
    const initUser = async () => {
      try {
        let result = await getToken();
        if (result && result.access_token && result.info) {
          setUser(result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    localStorage.getItem("authenticated") === "true" && initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * login and set user data
   * @param {String} email user email
   * @param {String} password user password
   */
  const login = async (email, password) => {
    let response = await fetch(`${LITEUSER}/auth/login_spa`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
    });

    
    let data = await response.json();
    setUser(data);

    return data;
  };

  /**
   * User logout & reset user
   */
  const logout = async () => {
    resetUser();

    let response = await fetch(`${LITEUSER}/auth/logout_spa`, {
      method: "POST",
      credentials: "include",
    });

    let data = await response.json();

    return data;
  };

  /**
   * get token and user info
   */
  const getToken = async () => {
    let response = await fetch(`${LITEUSER}/auth/access_token_spa`, {
      method: "GET",
      credentials: "include",
    });

    let data = await response.json();
    return data;
  };

  /**
   * Get allowed paths based on user scopes
   * All user can access general path: scope config === "*"
   * @param {String[]} scopes user scopes
   */
  const getAllowedPaths = (scopes) => {
    let paths = [];

    let generalPaths = scopeConfig.find(({ scope }) => scope === "*");
    generalPaths.pages.forEach((v) => !paths.includes(v) && paths.push(v));

    if (scopes && scopes.length > 0) {
      let privatePaths = scopeConfig.filter(({ scope }) =>
        scopes.includes(scope)
      );
      privatePaths.forEach(({ pages }) =>
        pages.forEach((v) => !paths.includes(v) && paths.push(v))
      );
    }

    return paths;
  };

  /**
   * Set user state
   * @param {Object} param
   * @param {String} param.access_token user access_token
   * @param {Object} param.info user info
   */
  const setUser = async ({ access_token, info }) => {
    let paths = getAllowedPaths(info.scopes);
    setAllowedPaths(paths);

    let allUsersResponse = await Req.get(`${API_URL}/user_edaun/id,first_name,last_name?_per_page=250`, {accessToken: access_token})

    setAccessToken(access_token);
    setUserInfo(info);
    setAllUsers(allUsersResponse.records);
    localStorage.setItem("authenticated", "true");
  };

  /**
   * reset user state
   */
  const resetUser = () => {
    setAccessToken(null);
    setUserInfo(null);
    setAllowedPaths([]);
    localStorage.removeItem("authenticated");
  };

  /**
   * return access_token if user is authenticated otherwise return null
   */
  const isAuthenticated = () => {
    return accessToken;
  };

  /**
   * get user full name: first_name + last_name
   */
  const getName = () => {
    let temp = [];
    userInfo && userInfo.first_name && temp.push(userInfo.first_name);
    userInfo && userInfo.last_name && temp.push(userInfo.last_name);

    return capitalize(temp.join(" "));
  };

  return {
    accessToken,
    userInfo,
    allUsers,
    allowedPaths,
    setUser,
    resetUser,
    getName,
    login,
    logout,
    isAuthenticated,
  };
};

export { useAuth };
