"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productStatus = exports.statusCatalog = exports.STATUS_BANNED = exports.STATUS_DELETED = exports.STATUS_REJECTED = exports.STATUS_INACTIVE = exports.STATUS_ACTIVE = exports.STATUS_INVALID = exports.STATUS_NEW = exports.STATUS_APPROVED = exports.STATUS_REVISED = exports.STATUS_REACTIVATED = exports.STATUS_READY = exports.STATUS_ACTIVEOK = void 0;
var STATUS_ACTIVEOK = 65; // YES

exports.STATUS_ACTIVEOK = STATUS_ACTIVEOK;
var STATUS_READY = 16;
exports.STATUS_READY = STATUS_READY;
var STATUS_REACTIVATED = 8;
exports.STATUS_REACTIVATED = STATUS_REACTIVATED;
var STATUS_REVISED = 4;
exports.STATUS_REVISED = STATUS_REVISED;
var STATUS_APPROVED = 1;
exports.STATUS_APPROVED = STATUS_APPROVED;
var STATUS_NEW = 2;
exports.STATUS_NEW = STATUS_NEW;
var STATUS_INVALID = -3; // invalid

exports.STATUS_INVALID = STATUS_INVALID;
var STATUS_ACTIVE = 64; // valid

exports.STATUS_ACTIVE = STATUS_ACTIVE;
var STATUS_INACTIVE = 0; // status awal

exports.STATUS_INACTIVE = STATUS_INACTIVE;
var STATUS_REJECTED = -1;
exports.STATUS_REJECTED = STATUS_REJECTED;
var STATUS_DELETED = -127;
exports.STATUS_DELETED = STATUS_DELETED;
var STATUS_BANNED = -126; // duplikat

exports.STATUS_BANNED = STATUS_BANNED;
var statusCatalog = {
  COMPLETE: 65,
  AVAILABLE: 16,
  PENDING_EVALUATE: 8,
  EVALUATE: 4,
  CROP: 2,
  INPUT: 64,
  OPEN: 0,
  NOT_AVAILABLE: -1,
  DELETED: -127
};
exports.statusCatalog = statusCatalog;
var productStatus = {
  VALID: 65,
  ACTIVE: 64,
  TRIVIAL: 16,
  PSEUDO: 2,
  INACTIVE: 0,
  INVALID: -3,
  DUPLICATED: -126,
  DELETED: -127
};
exports.productStatus = productStatus;