import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  logo: {
    width: "30px",
    margin: theme.spacing(1)
  },
  title: {
    color: theme.palette.primary.contrastText
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div>
      <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
        <Toolbar>
          <RouterLink to="/">
            <img alt="eDaun" src="/images/logos/edaun.png" className={classes.logo} />
          </RouterLink>
          <RouterLink to="/">
            <h2 className={classes.title}>{props.title ? props.title : "TopBar"}</h2>
          </RouterLink>
        </Toolbar>
      </AppBar>
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
