"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatFileName = exports.formatDateTime = void 0;

var formatDateTime = function formatDateTime(date) {
  return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate(), "_").concat(date.getHours()).concat(date.getMinutes()).concat(date.getSeconds());
};

exports.formatDateTime = formatDateTime;

var formatFileName = function formatFileName(original) {
  return "".concat(Math.random().toString(32).slice(2), "_").concat(formatDateTime(new Date()), "_").concat(original);
};

exports.formatFileName = formatFileName;