import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    main: "#002d20",
    dark: "#002d20",
    light: "#1b5e20"
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[900],
    light: colors.blue[600]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[900],
    light: colors.red[600]
  },
  text: {
    primary: colors.blueGrey[100],
    secondary: colors.blueGrey[200],
    link: colors.blue[100]
  },
  link: colors.blue[100],
  icon: colors.blueGrey[100],
  background: {
    default: "#1e1e1e",
    paper: black
  },
  divider: colors.grey[100],
  type: "dark"
};
