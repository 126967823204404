import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { renderRoutes } from "react-router-config";

import { lightTheme } from "./theme";
import routes from "./routes";
import { UserContext } from "auth/UserContext";
import { useAuth } from "auth/useAuth";

const history = createBrowserHistory();

function App() {
  const auth = useAuth();

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <UserContext.Provider value={auth}>
        <Router history={history}>{renderRoutes(routes)}</Router>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
