/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import DashboardLayout from "./layouts/Dashboard";
import ErrorLayout from "./layouts/Error";
import AuthLayout from "./layouts/Auth";
import { privateRoute } from "auth/PrivateRoute";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("views/Login")),
      },
      {
        path: "/auth/logout",
        exact: true,
        component: lazy(() => import("auth/Logout")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: lazy(() => import("views/Error404")),
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("views/Error404")),
      },
      {
        path: "/errors/error-403",
        exact: true,
        component: lazy(() => import("views/Error404/Error403")),
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: lazy(() => import("views/Error404")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: "/home",
        exact: true,
        component: lazy(() => import("views/Home")),
        render: privateRoute,
      },
      {
        path: "/hematid/biz/edit/:id",
        exact: true,
        component: lazy(() => import("views/Biz/EditBiz")),
        render: privateRoute,
      },
      {
        path: "/hematid/biz/add",
        exact: true,
        component: lazy(() => import("views/Biz/AddBiz")),
        render: privateRoute,
      },
      {
        path: "/hematid/biz",
        exact: true,
        component: lazy(() => import("views/Biz")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog/:catalogId/page/:pageId/clippings",
        exact: true,
        component: lazy(() => import("views/CatalogPageClipping")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog/edit/:id",
        exact: true,
        component: lazy(() => import("views/Catalog/EditCatalog")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog/add",
        exact: true,
        component: lazy(() => import("views/Catalog/AddCatalog")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog/:id/pages",
        exact: true,
        component: lazy(() => import("views/Catalog")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog",
        exact: true,
        component: lazy(() => import("views/Catalog")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog_source/export/:id",
        exact: true,
        component: lazy(() => import("views/CatalogSource/ExportCatalog")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog_source/edit/:id",
        exact: true,
        component: lazy(() => import("views/CatalogSource/EditCatalog")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog_source",
        exact: true,
        component: lazy(() => import("views/CatalogSource")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog_category",
        exact: true,
        component: lazy(() => import("views/CatalogCategory")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog_kind/edit/:id",
        exact: true,
        component: lazy(() => import("views/CatalogKind/EditCatalogKind")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog_kind/add",
        exact: true,
        component: lazy(() => import("views/CatalogKind/AddCatalogKind")),
        render: privateRoute,
      },
      {
        path: "/hematid/catalog_kind",
        exact: true,
        component: lazy(() => import("views/CatalogKind")),
        render: privateRoute,
      },
      {
        path: "/hematid/city_tag",
        exact: true,
        component: lazy(() => import("views/CityTag")),
        render: privateRoute,
      },
      {
        path: "/hematid/geo_tag",
        exact: true,
        component: lazy(() => import("views/GeoTag")),
        render: privateRoute,
      },
      {
        path: "/hematid/promo",
        exact: true,
        component: lazy(() => import("views/Promo")),
        render: privateRoute,
      },
      {
        path: "/hematid/promo/add",
        exact: true,
        component: lazy(() => import("views/Promo/AddPromo")),
        render: privateRoute,
      },
      {
        path: "/hematid/promo/edit/:id",
        exact: true,
        component: lazy(() => import("views/Promo/EditPromo")),
        render: privateRoute,
      },
      {
        path: "/hematid/sku/mapping/:from-:to",
        component: lazy(() => import("views/Product/components/MappingSku")),
        render: privateRoute,
      },
      {
        path: "/hematid/sku",
        component: lazy(() => import("views/Sku")),
        render: privateRoute,
      },
      {
        path: "/hematid/sku2/add/:productId",
        component: lazy(() => import("views/Sku2/InputSkuValid")),
        render: privateRoute,
      },
      {
        path: "/hematid/sku2/migration",
        component: lazy(() => import("views/Sku2/SkuMigration")),
        render: privateRoute,
      },
      {
        path: "/hematid/sku2/add",
        component: lazy(() => import("views/Sku2/InputSkuValid")),
        render: privateRoute,
      },
      {
        path: "/hematid/sku2",
        component: lazy(() => import("views/Sku2")),
        render: privateRoute,
      },
      {
        path: "/hematid/product/add",
        component: lazy(() => import("views/Product/components/AddProduct")),
        render: privateRoute,
      },
      {
        path: "/hematid/product/edit/:id",
        component: lazy(() => import("views/Product/components/EditProduct")),
        render: privateRoute,
      },
      {
        path: "/hematid/product/duplicate",
        component: lazy(() =>
          import("views/Product/components/DuplicateProduct")
        ),
        render: privateRoute,
      },
      {
        path: "/hematid/product",
        component: lazy(() => import("views/Product/ProductTable")),
        render: privateRoute,
      },
      {
        path: "/hematid/product_brand*",
        exact: true,
        component: lazy(() => import("views/ProductBrand")),
        render: privateRoute,
      },
      {
        path: "/hematid/product_category*",
        exact: true,
        component: lazy(() => import("views/ProductCategory")),
        render: privateRoute,
      },
      {
        path: "/hematid/carousel_home/edit/:id",
        exact: true,
        component: lazy(() => import("views/CarouselHome/EditCarousel")),
        render: privateRoute,
      },
      {
        path: "/hematid/carousel_home/add",
        exact: true,
        component: lazy(() => import("views/CarouselHome/AddCarousel")),
        render: privateRoute,
      },
      {
        path: "/hematid/carousel_home",
        exact: true,
        component: lazy(() => import("views/CarouselHome")),
        render: privateRoute,
      },
      {
        path: "/hematid/users",
        exact: true,
        component: lazy(() => import("views/User")),
        render: privateRoute,
      },
      {
        path: "/hematid/user_dashboard",
        exact: true,
        component: lazy(() => import("views/UserDashboard")),
        render: privateRoute,
      },
      {
        path: "/hematid/groups",
        exact: true,
        component: lazy(() => import("views/Group")),
        render: privateRoute,
      },
      {
        path: "/hematid/scopes",
        exact: true,
        component: lazy(() => import("views/Scope")),
        render: privateRoute,
      },
      {
        path: "/hematid/user_fee",
        exact: true,
        component: lazy(() => import("views/UserFee")),
        render: privateRoute,
      },
      {
        path: "/hematid/manufacturer",
        exact: true,
        component: lazy(() => import("views/Manufacturer")),
        render: privateRoute,
      },
      {
        path: "/hematid/user_fee/edit/:id",
        exact: true,
        component: lazy(() => import("views/UserFee/EditUserFee")),
        render: privateRoute,
      },
      {
        path: "/hematid/user_fee/add",
        exact: true,
        component: lazy(() => import("views/UserFee/AddUserFee")),
        render: privateRoute,
      },
      {
        path: "/hematid/product_promo_req",
        exact: true,
        component: lazy(() => import("views/ProductPromoReq")),
        render: privateRoute,
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

export default routes;
