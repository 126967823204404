"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUppy = void 0;

var _react = require("react");

var _core = _interopRequireDefault(require("@uppy/core"));

var _imageEditor = _interopRequireDefault(require("@uppy/image-editor"));

var _awsS = _interopRequireDefault(require("@uppy/aws-s3"));

var _url = _interopRequireDefault(require("@uppy/url"));

var _formatting = require("./formatting");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useUppy = function useUppy(_ref) {
  var folderPath = _ref.folderPath,
      companionUrl = _ref.companionUrl,
      maxNumberOfFiles = _ref.maxNumberOfFiles,
      cropOptions = _ref.cropOptions,
      editorActions = _ref.editorActions,
      onSuccess = _ref.onSuccess,
      onComplete = _ref.onComplete;
  var uppy = (0, _react.useMemo)(function () {
    // Do all the configuration here
    var uppyTemp = (0, _core["default"])({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: maxNumberOfFiles
      },
      onBeforeFileAdded: function onBeforeFileAdded(currentFile, files) {
        if (!currentFile.type) {
          // log to console
          uppy.log("Skipping file because it has no type"); // show error message to the user

          uppy.info("Skipping file because it has no type", "error", 500);
          return false;
        }
      },
      onBeforeUpload: function onBeforeUpload(files) {
        //menentukan folder spesifik
        for (var prop in files) {
          files[prop].name = "".concat(folderPath, "/").concat((0, _formatting.formatFileName)(files[prop].name));
          files[prop].meta.name = "".concat(folderPath, "/").concat((0, _formatting.formatFileName)(files[prop].meta.name));
        }
      }
    });
    uppyTemp.use(_awsS["default"], {
      companionUrl: companionUrl
    });
    uppyTemp.use(_imageEditor["default"], {
      id: "ImageEditor",
      quality: 0.8,
      cropperOptions: _objectSpread({
        viewMode: 1,
        background: false,
        autoCropArea: 1,
        responsive: true
      }, cropOptions),
      actions: _objectSpread({
        revert: false,
        rotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false
      }, editorActions)
    });
    uppyTemp.use(_url["default"], {
      id: "UrlUpload",
      companionUrl: companionUrl,
      locale: {}
    }); // Fired each time a single upload is completed.

    uppyTemp.on("upload-success", function (file, response) {
      onSuccess && onSuccess(file, response);
    }); // Fired when all uploads are complete.

    uppyTemp.on("complete", function (result) {
      onComplete && onComplete(result);
    });
    return uppyTemp; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  (0, _react.useEffect)(function () {
    return function () {
      return uppy.close();
    };
  }, []);
  return {
    uppy: uppy
  };
};

exports.useUppy = useUppy;