"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _reactRouter = require("react-router");

var _default = function _default() {
  return (0, _react.useContext)(_reactRouter.__RouterContext);
};

exports["default"] = _default;