import React, { Suspense, useState } from "react";
import { renderRoutes } from "react-router-config";
import { LinearProgress } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { TopBar, NavBar } from "./components";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  topBar: {
    zIndex: 2,
    position: "relative",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  main: {
    width: "-webkit-fill-available",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  mainShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  content: {
    padding: theme.spacing(1),
  },
}));

export const DashboardContext = React.createContext();

export default function Dashboard(props) {
  const { route } = props;

  const classes = useStyles();
  const [isdrawerOpen, setDrawerOpen] = useState(true);
  const [title, setTitle] = useState("Admin Console");

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root}>
      <TopBar
        title={title}
        isDrawerOpen={isdrawerOpen}
        onDrawerOpen={handleDrawerOpen}
        className={classes.topBar}
      />
      <NavBar isDrawerOpen={isdrawerOpen} onDrawerClose={handleDrawerClose} />

      <main
        className={clsx(classes.main, {
          [classes.mainShift]: isdrawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Suspense fallback={<LinearProgress />}>
          <DashboardContext.Provider
            value={{
              navBarOpen: isdrawerOpen,
              closeNavBar: handleDrawerClose,
              setTopBarTitle: setTitle,
            }}
          >
            <div className={classes.content}>{renderRoutes(route.routes)}</div>
          </DashboardContext.Provider>
        </Suspense>
      </main>
    </div>
  );
}
