"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMeasurementUnitOption = exports.measurementUnitOptions = exports.getItemUnitOption = exports.itemUnitOptions = exports.getBizTypeOption = exports.bizTypeOptions = exports.getCarouselTypeOption = exports.carouselTypeOptions = exports.getStatusCatalogOption = exports.statusCatalogOptions = exports.getStatusOption = exports.statusOptions = void 0;

var _constants = require("./constants");

var statusOptions = [{
  label: "valid",
  value: _constants.STATUS_ACTIVEOK
}, {
  label: "ready",
  value: _constants.STATUS_READY
}, {
  label: "reactivated",
  value: _constants.STATUS_REACTIVATED
}, {
  label: "revised",
  value: _constants.STATUS_REVISED
}, {
  label: "approved",
  value: _constants.STATUS_APPROVED
}, {
  label: "new",
  value: _constants.STATUS_NEW
}, {
  label: "invalid",
  value: _constants.STATUS_INVALID
}, {
  label: "active",
  value: _constants.STATUS_ACTIVE
}, {
  label: "inactive",
  value: _constants.STATUS_INACTIVE
}, {
  label: "deleted",
  value: _constants.STATUS_DELETED
}, {
  label: "duplicated",
  value: _constants.STATUS_BANNED
}];
/**
 * untuk inisialisasi status autocomplete
 * @param {*} status
 */

exports.statusOptions = statusOptions;

var getStatusOption = function getStatusOption(status) {
  return statusOptions.find(function (_ref) {
    var value = _ref.value;
    return value.toString() === status.toString();
  });
};

exports.getStatusOption = getStatusOption;
var statusCatalogOptions = [{
  label: "complete",
  value: 65
}, {
  label: "available",
  value: 16
}, {
  label: "pending evaluate",
  value: 8
}, {
  label: "evaluate",
  value: 4
}, {
  label: "crop",
  value: 2
}, {
  label: "input",
  value: 64
}, {
  label: "open",
  value: 0
}, {
  label: "not available",
  value: -1
}, {
  label: "deleted",
  value: -127
}];
/**
 * untuk inisialisasi status autocomplete
 * @param {*} status
 */

exports.statusCatalogOptions = statusCatalogOptions;

var getStatusCatalogOption = function getStatusCatalogOption(status) {
  return statusCatalogOptions.find(function (_ref2) {
    var value = _ref2.value;
    return value.toString() === status.toString();
  });
};

exports.getStatusCatalogOption = getStatusCatalogOption;
var carouselTypeOptions = [{
  label: "home",
  value: 1
}, {
  label: "coupon",
  value: 2
}];
/**
 * untuk inisialisasi carousel type autocomplete
 * @param {Object[]} type
 */

exports.carouselTypeOptions = carouselTypeOptions;

var getCarouselTypeOption = function getCarouselTypeOption(type) {
  return carouselTypeOptions.find(function (_ref3) {
    var value = _ref3.value;
    return value.toString() === type.toString();
  });
};

exports.getCarouselTypeOption = getCarouselTypeOption;
var bizTypeOptions = [{
  label: "MERCHANT",
  value: 1
}, {
  label: "BANK",
  value: 2
}, {
  label: "BRAND",
  value: 3
}, {
  label: "BRANCH",
  value: 4
}, {
  label: "COMMERCIAL_CENTER",
  value: 5
}, {
  label: "SUPERMARKET",
  value: 6
}, {
  label: "ONLINE_STORE",
  value: 7
}];
/**
 * mengembalikan option {label, value} dari biz type tertentu
 * @param {Integer} type biz type
 */

exports.bizTypeOptions = bizTypeOptions;

var getBizTypeOption = function getBizTypeOption(type) {
  return bizTypeOptions.find(function (_ref4) {
    var value = _ref4.value;
    return value.toString() === type.toString();
  });
};

exports.getBizTypeOption = getBizTypeOption;
var itemUnitOptions = [{
  label: "bag",
  value: "bag"
}, {
  label: "botol",
  value: "botol"
}, {
  label: "box",
  value: "box"
}, {
  label: "bungkus",
  value: "bungkus"
}, {
  label: "cup",
  value: "cup"
}, {
  label: "gr",
  value: "gr"
}, {
  label: "ikt",
  value: "ikt"
}, {
  label: "jar",
  value: "jar"
}, {
  label: "jerigen",
  value: "jerigen"
}, {
  label: "kaleng",
  value: "kaleng"
}, {
  label: "karton",
  value: "karton"
}, {
  label: "kg",
  value: "kg"
}, {
  label: "pck",
  value: "pck"
}, {
  label: "pcs",
  value: "pcs"
}, {
  label: "pet",
  value: "pet"
}, {
  label: "pouch",
  value: "pouch"
}, {
  label: "sachet",
  value: "sachet"
}, {
  label: "sak",
  value: "sak"
}, {
  label: "str",
  value: "str"
}, {
  label: "tpk",
  value: "tpk"
}, {
  label: "tub",
  value: "tub"
}];
/**
 * mengembalikan option {label, value} dari item unit tertentu
 * @param {String} unit item unit
 */

exports.itemUnitOptions = itemUnitOptions;

var getItemUnitOption = function getItemUnitOption(unit) {
  return itemUnitOptions.find(function (_ref5) {
    var value = _ref5.value;
    return value.toString() === unit.toString();
  });
};

exports.getItemUnitOption = getItemUnitOption;
var measurementUnitOptions = [{
  label: "gr",
  value: "gr"
}, {
  label: "kg",
  value: "kg"
}, {
  label: "ltr",
  value: "ltr"
}, {
  label: "ml",
  value: "ml"
}, {
  label: "pcs",
  value: "pcs"
}, {
  label: "roll",
  value: "roll"
}, {
  label: "sheet",
  value: "sheet"
}];
/**
 * mengembalikan option {label, value} dari measurement unit tertentu
 * @param {String} unit measurement unit
 */

exports.measurementUnitOptions = measurementUnitOptions;

var getMeasurementUnitOption = function getMeasurementUnitOption(unit) {
  return measurementUnitOptions.find(function (_ref6) {
    var value = _ref6.value;
    return value.toString() === unit.toString();
  });
};

exports.getMeasurementUnitOption = getMeasurementUnitOption;