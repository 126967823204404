"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CatalogPageList", {
  enumerable: true,
  get: function get() {
    return _CatalogPageList["default"];
  }
});
Object.defineProperty(exports, "ClippingList", {
  enumerable: true,
  get: function get() {
    return _ClippingList["default"];
  }
});

var _CatalogPageList = _interopRequireDefault(require("./CatalogPageList"));

var _ClippingList = _interopRequireDefault(require("./ClippingList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }