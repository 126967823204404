import { UserContext } from "auth/UserContext";
import React from "react";
import { Redirect } from "react-router-dom";
import UrlPattern from "url-pattern";

export const privateRoute = ({ location, route, ...props }) => {
  return (
    <UserContext.Consumer>
      {({ isAuthenticated, allowedPaths }) => (
        <>
          {isAuthenticated() &&
          allowedPaths.find((v) =>
            new UrlPattern(v).match(location.pathname)
          ) ? (
            <route.component route={route} {...props} />
          ) : isAuthenticated() ? ( // user tries to access restricted path
            <Redirect
              to={{
                pathname: "/errors/error-403",
              }}
            />
          ) : (
            // user is not logged in
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: location },
              }}
            />
          )}
        </>
      )}
    </UserContext.Consumer>
  );
};
