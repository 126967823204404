import { createMuiTheme } from "@material-ui/core";

import palette from "./palette";
import paletteDark from "./paletteDark";
import typography from "./typography";
import overrides from "./overrides";

const lightTheme = createMuiTheme({
  palette,
  typography: typography(palette),
  overrides
});

const darkTheme = createMuiTheme({
  palette: paletteDark,
  typography: typography(paletteDark),
  overrides
});

export { lightTheme, darkTheme };
