/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import ListAltIcon from "@material-ui/icons/ListAlt";

export default [
  {
    title: "hemat.id",
    pages: [
      {
        title: "Home",
        href: "/home",
        icon: HomeIcon,
      },
      {
        title: "Bisnis",
        href: "/hematid/biz",
        icon: BusinessIcon,
      },
      {
        title: "Produk",
        href: "/hematid/tables",
        icon: ListAltIcon,
        children: [
          {
            title: "Brand",
            href: "/hematid/product_brand",
          },
          {
            title: "Manufacturer",
            href: "/hematid/manufacturer",
          },
          {
            title: "Kategori",
            href: "/hematid/product_category",
          },
          {
            title: "Produk",
            href: "/hematid/product",
          },
          // {
          //   title: "SKU",
          //   href: "/hematid/sku",
          // },
          {
            title: "SKU",
            href: "/hematid/sku2",
          },
        ],
      },
      {
        title: "Katalog & Promo",
        href: "/hematid/promo",
        icon: ReceiptIcon,
        children: [
          {
            title: "Jenis Katalog",
            href: "/hematid/catalog_kind",
          },
          {
            title: "Katalog",
            href: "/hematid/catalog",
          },
          {
            title: "Sumber Katalog",
            href: "/hematid/catalog_source",
          },
          {
            title: "Kategori Katalog",
            href: "/hematid/catalog_category",
          },
          {
            title: "Promo",
            href: "/hematid/promo",
          },
          {
            title: "Syarat Promo",
            href: "/hematid/product_promo_req",
          },
        ],
      },
      {
        title: "Others",
        href: "/hematid/others",
        icon: DashboardIcon,
        children: [
          {
            title: "City Tag",
            href: "/hematid/city_tag",
          },
          {
            title: "Geo Tag",
            href: "/hematid/geo_tag",
          },
          {
            title: "Carousel",
            href: "/hematid/carousel_home",
          },
        ],
      },
      {
        title: "User",
        href: "/hematid/user",
        icon: PeopleIcon,
        children: [
          {
            title: "Users",
            href: "/hematid/users",
          },
          {
            title: "Groups",
            href: "/hematid/groups",
          },
          {
            title: "Permissions",
            href: "/hematid/scopes",
          },
          {
            title: "Fee",
            href: "/hematid/user_fee",
          },
          {
            title: "User Dashboard",
            href: "/hematid/user_dashboard",
          },
        ],
      },
    ],
  },
  {
    title: "Support",
    pages: [
      {
        title: "Login",
        href: "/auth/login",
        icon: PresentToAllIcon,
      },
      {
        title: "Logout",
        href: "/auth/logout",
        icon: PresentToAllIcon,
      },
    ],
  },
];
